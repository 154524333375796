import { Routes, Route } from "react-router-dom";
import { About } from "./ui/about/about";
import { Contributors } from "./ui/contributors/contributors";
import { Dictionary } from "./ui/dictionary/dictionary";

import { Generate } from './ui/generate/generate';
import { Layout } from './ui/layout/layout';
import { NoMatch } from "./ui/nomatch/nomatch";
import { Word } from "./ui/word/word";

export const Routing = () => {
  return (<Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Generate />} />
      <Route path="words" element={<Dictionary />} />
      <Route path="contributors" element={<Contributors />} />
      <Route path="about" element={<About />} />
      <Route path="word/:id" element={<Word />} />

      {/* Using path="*"" means "match anything", so this route
            acts like a catch-all for URLs that we don't have explicit
            routes for. */}
      <Route path="*" element={<NoMatch />} />
    </Route>
  </Routes>);
}