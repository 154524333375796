const API_URL = "https://api.godt-ord-igen.dk";

export type GeneratedWord = {
  word: string;
  type: "substantiv" | "verber" | "adverbier" | "adjektiv";
};

export interface GenerateResult {
  first: GeneratedWord;
  second: GeneratedWord;
}

export interface ContributorListEntry { 
  /** Contributor name */
  author: string; 
  /** Number of contributions */
  count: number;
}
export interface ContributorsList {
  info: {
    /** The total number of entries */
    total: number;
    /** The total number of pages */
    pages: number;
    /** The current page (0 index) */
    page: number;
  };
  contributors: ContributorListEntry[];
}

export interface WordListEntry { 
  /** ID of word */
  id: string;
  /** The word */
  word: string;
  /** Explanation */
  explanation: string; 
  /** Number of likes */
  likes: number;
  /** Created date (YYYY-MM-DD HH:mm:ss) */
  created: string;
}
export interface WordsList {
  info: {
    /** The total number of entries */
    total: number;
    /** The total number of pages */
    pages: number;
    /** The current page (0 index) */
    page: number;
  };
  words: WordListEntry[];
}

type APIListParams = {
  [param: string]: string;
}

export interface AlternateExplanation {
  /** ID of alternate explanation */
  id: string;
  /** The ID of the word that it is an alternate explanation to */
  word_id: string;
  /** The author */
  author: string;
  /** Explanation */
  explanation: string; 
  /** Number of likes */
  likes: string;
  /** Created date (YYYY-MM-DD HH:mm:ss) */
  created: string;
  /** Modified date (YYYY-MM-DD HH:mm:ss) */
  modified: string;
}

export interface WordEntry {
  word: {
    /** ID of word */
    id: string;
    /** The word */
    word: string;
    /** The author */
    author: string;
    /** Explanation */
    explanation: string; 
    /** Number of likes */
    likes: string;
    /** Created date (YYYY-MM-DD HH:mm:ss) */
    created: string;
    /** Modified date (YYYY-MM-DD HH:mm:ss) */
    modified: string;
  };
  alternate: AlternateExplanation[];
}

export interface SaveWordSuccess {
  /** ID of the word added */
  newid: number;
  /** New total number of words */
  total: string;
}

export interface SaveAlternateSuccess {
  /** ID of the alternate explanation added */
  newid: number;
}
export interface SaveGeneralSuccess {
  /** Success message */
  success: string;
}
export interface APIError {
  /** Error description */
  errors: string[];
}

export const isAPIError = (e: any): e is APIError => (e as APIError).errors !== undefined;

const totalWords = async () => {
  let total = 0;
  try {
    const resp = await fetch(`${API_URL}/total.php`);
    const result = await resp.json();
    total = result.total;
  } catch (e) {
    console.error("Error fetching total", e);
  }
  return total;
};

const generate = async () => {
  try {
    const resp = await fetch(`${API_URL}/generate.php`);
    const result: GenerateResult = await resp.json();
    return result;
  } catch (e) {
    console.error("Error fetching total", e);
  }
  return null;
};

const contributors = async (
  page: number,
  letter: string,
  orderby: string,
  orderbydir: string
) => {
  const params: APIListParams = {
    page: `${page}`,
    letter,
    orderby,
    orderbydir,
  };
  if (letter === 'alle') {
    delete params.letter;
  }
  try {
    const resp = await fetch(
      `${API_URL}/contributors.php?` +
        new URLSearchParams(params)
    );
    const result: ContributorsList = await resp.json();
    return result;
  } catch (e) {
    console.error("Error fetching contributors", e);
  }
  return null;
};

const words = async (
  page: number,
  letter: string,
  orderby: string,
  orderbydir: string
) => {
  const params: APIListParams = {
    page: `${page}`,
    letter,
    orderby,
    orderbydir,
  };
  if (letter === 'alle') {
    delete params.letter;
  }
  try {
    const resp = await fetch(
      `${API_URL}/words.php?` +
        new URLSearchParams(params)
    );
    const result: WordsList = await resp.json();
    return result;
  } catch (e) {
    console.error("Error fetching words", e);
  }
  return null;
};

const contribute = async (word: string, author: string, explanation: string, captcha: string) => {
  if (word && captcha) {
    try {
      const data = new FormData();
      data.set('word', word);
      data.set('author', author);
      data.set('explanation', explanation);
      data.set('captcha', captcha);

      const resp = await fetch(
        `${API_URL}/save-word.php`,
        {
          method: 'POST',
          body: data
        }
      );
      const result: SaveWordSuccess | APIError = await resp.json();
      return result;
    } catch (e) {
      console.error("Error posting word", e);
    }
  }
}

const alternate = async (word: string, author: string, explanation: string, captcha: string) => {
  if (word && explanation && captcha) {
    try {
      const data = new FormData();
      data.set('word', word);
      data.set('author', author);
      data.set('explanation', explanation);
      data.set('captcha', captcha);

      const resp = await fetch(
        `${API_URL}/save-alternate.php`,
        {
          method: 'POST',
          body: data
        }
      );
      const result: SaveAlternateSuccess | APIError = await resp.json();
      return result;
    } catch (e) {
      console.error("Error posting word", e);
    }
  }
}

const like = async (word: string, table: 'word' | 'alternate' = 'word') => {
  if (word) {
    try {
      const data = new FormData();
      data.set('word', word);
      data.set('type', table);

      const resp = await fetch(
        `${API_URL}/like.php`,
        {
          method: 'POST',
          body: data
        }
      );
      const result: SaveGeneralSuccess | APIError = await resp.json();
      return result;
    } catch (e) {
      console.error("Error posting word", e);
    }
  }
}

const word = async (
  id: string
) => {
  const params: APIListParams = {
    id,
  };
  try {
    const resp = await fetch(
      `${API_URL}/word.php?` +
        new URLSearchParams(params)
    );
    const result: WordEntry = await resp.json();
    return result;
  } catch (e) {
    console.error("Error fetching single word", e);
  }
  return null;
};

export const api = {
  totalWords,
  generate,
  contributors,
  words,
  contribute,
  alternate,
  word,
  like,
};
