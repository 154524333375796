import { CSSProperties } from "react";
import "./loading.css";

interface Props {
  color?: string;
}

interface LoadingCSS extends CSSProperties {
  "--uib-size"?: string;
  "--uib-speed"?: string;
  "--uib-color"?: string;
}

export const Loading: React.FC<Props> = ({ color = "#95B3D7" }) => {
  const style: LoadingCSS = {
    "--uib-color": color,
  };
  return (
    <div className="newtons-cradle" style={style}>
      <div className="newtons-cradle__dot"></div>
      <div className="newtons-cradle__dot"></div>
      <div className="newtons-cradle__dot"></div>
      <div className="newtons-cradle__dot"></div>
    </div>
  );
};
