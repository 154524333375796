import { createRef, FormEvent, useCallback, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { api, isAPIError, WordEntry } from "../../api/words-api";
import { notificationsActions } from "../../store/notifications/notificationsSlice";
import { HTMLSanitizer } from "../../utils/html-sanitizer";
import { TextEditor } from "../components/text-editor/text-editor";

import "./style.css";

export const Word = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [word, setWord] = useState<WordEntry>();
  const [author, setAuthor] = useState("");
  const [description, setDescription] = useState("");
  const captchaRef = createRef<ReCAPTCHA>();

  const fetchWord = useCallback((id?: string) => {
    if (id) {
      api.word(id).then((w) => {
        if (w) {
          setWord(w);
        }
      });
    }
  }, []);

  useEffect(() => {
    fetchWord(id);
  }, [fetchWord, id]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!id) {
      return;
    }
    if (!description) {
      dispatch(
        notificationsActions.addNotification({
          id: (Math.random() + 1).toString(36).substring(7), // generate "random" id
          type: "error",
          header: "Fejl",
          text: `En alternativ forklaring skal gives.`,
        })
      );
    } else {
      const captchaToken = captchaRef.current?.getValue() ?? "";
      captchaRef.current?.reset();
      api.alternate(id, author, description, captchaToken).then((r) => {
        if (r && !isAPIError(r)) {
          dispatch(
            notificationsActions.addNotification({
              id: "alternate-" + r.newid,
              type: "success",
              header: "Forklaring tilføjet",
              text: `Forklaringen blev tilføjet til ordbogen`,
            })
          );
          fetchWord(id);
          setDescription("");
        } else {
          dispatch(
            notificationsActions.addNotification({
              id: (Math.random() + 1).toString(36).substring(7), // generate "random" id
              type: "error",
              header: "Fejl",
              text: `Der skete desværre en fejl. Prøv igen`,
            })
          );
        }
      });
    }
  };

  const like = useCallback((word?: string, table: 'word' | 'alternate' = 'word') => {
    if (word) {
      api.like(word, table).then((r) => {
        if (r && !isAPIError(r)) {
          fetchWord(id);
        } else {
          dispatch(
            notificationsActions.addNotification({
              id: (Math.random() + 1).toString(36).substring(7), // generate "random" id
              type: "error",
              header: "Fejl",
              text: `Der skete desværre en fejl. Prøv igen`,
            })
          );
        }
      });
    }
  }, [dispatch, fetchWord, id])

  const sitekey = process.env.REACT_APP_CAPTCHA_SITE_KEY ?? "";

  return (
    <>
      <div className="container content">
        <div className="row">
          <div className="col-md-12">
            <h2>{word?.word.word}</h2>
            <div
              className="explanation"
              dangerouslySetInnerHTML={{
                __html: HTMLSanitizer.stripHTML(word?.word.explanation),
              }}
            ></div>
            <p>
              <button onClick={() => like(word?.word.id, 'word')} className="btn btn-default">
                <i className="bi bi-hand-thumbs-up"></i>
              </button>{" "}
              <span className="badge bg-secondary">{word?.word.likes}</span> |
              Oprettet: {word?.word.created} af {word?.word.author}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {word?.alternate.map((a) => {
              return (
                <div className="alternate-explanation" key={a.id}>
                  <div
                    className="explanation"
                    dangerouslySetInnerHTML={{
                      __html: HTMLSanitizer.stripHTML(a.explanation),
                    }}
                  ></div>
                  <p>
                  <button onClick={() => like(a.id, 'alternate')} className="btn btn-default">
                    <i className="bi bi-hand-thumbs-up"></i>
                  </button>{" "}
                    <span className="badge bg-secondary">{a.likes}</span> |
                    Oprettet: {a.created} af {a.author}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="container content addalternate">
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <br />
                <div className="input text">
                  <label htmlFor="WordAuthor">
                    Indtast dit navn hvis du vil krediteres:
                  </label>
                  <input
                    className="form-control"
                    maxLength={1000}
                    type="text"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                  />
                </div>
              </div>

              <legend>
                Synes du ordet også kan have en anden betydning, så skriv den
                her:
              </legend>
              <TextEditor initialText={description} onChange={setDescription} />
              <br />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6  col-lg-4">
              <ReCAPTCHA sitekey={sitekey} ref={captchaRef} />
            </div>
            <div className="col-12 col-md-6 col-lg-8">
              <div className="d-grid">
                <input
                  className="btn btn-info btn-block"
                  type="submit"
                  value="Tilføj"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
