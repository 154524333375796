import { createSlice } from '@reduxjs/toolkit'

export interface WordsState {
  total: number;
}

const initialState: WordsState = {
  total: 0
};

export const wordsSlice = createSlice({
  name: 'words',
  initialState,
  reducers: {
    setTotal: (state, action) => {
      state.total = action.payload
    },
  }
});

export const wordsActions = wordsSlice.actions;
export const wordsReducer = wordsSlice.reducer;