import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../../api/words-api";
import { StoreState } from "../../../store/store-state";
import { wordsActions } from "../../../store/words/wordsSlice";

export const TotalWords = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    api.totalWords().then((total) => {
      dispatch(wordsActions.setTotal(total));
    })
  }, [dispatch]);

  const totalWords = useSelector((state: StoreState) => state.words.total);
  
  return (<span>
    P.t. <b>{totalWords}</b> ord i ordbogen
  </span>);
}