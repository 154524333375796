import React, { FormEvent, useCallback, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha"
import { useDispatch } from "react-redux";

import { api, GenerateResult, isAPIError } from "../../api/words-api";
import { notificationsActions } from "../../store/notifications/notificationsSlice";
import { wordsActions } from "../../store/words/wordsSlice";
import { Loading } from "../components/loading/loading";
import { TextEditor } from "../components/text-editor/text-editor";

import "./style.css";

export const Generate: React.FC = () => {
  const dispatch = useDispatch();
  const [joiner, setJoiner] = useState('');
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const captchaRef = React.createRef<ReCAPTCHA>();

  const [word, setWord] = useState<GenerateResult>();

  const generateWord = useCallback(
    (e?: any) => {
      setLoading(true);
      e?.preventDefault();
      api.generate().then((w) => {
        setLoading(false);
        if (w) {
          setWord(w);
        }
      });
    },
    [setWord, setLoading]
  );

  useEffect(() => {
    generateWord();
  }, [generateWord]);

  useEffect(() => {
    if (captchaRef.current) {
      console.log('Ref changed');
    }
  }, [captchaRef.current]);

  const changeJoiner = useCallback(
    (j: string) => (e: any) => {
      setJoiner(j);
      e.preventDefault();
    },
    [setJoiner]
  );

  const wordToShow = word ? word.first.word + joiner + word.second.word : "";

  const joiningOptions = [
    {
      joiner: "s",
      text: "Ja, et s",
    },
    {
      joiner: "e",
      text: "Ja, et e",
    },
    {
      joiner: "-",
      text: "Ja, en bindestreg",
    },
    {
      joiner: "",
      text: "Nej, ingenting",
    },
  ];

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!description) {
      dispatch(notificationsActions.addNotification({
        id: (Math.random() + 1).toString(36).substring(7), // generate "random" id
        type: 'error',
        header: 'Fejl',
        text: `Et ord skal have en forklaring for at tilføjes.`,
      }));
    } else {
      const captchaToken = captchaRef.current?.getValue() ?? '';
      captchaRef.current?.reset();
      api.contribute(wordToShow, author, description, captchaToken).then(r => {
        if (r && !isAPIError(r)) {
          dispatch(wordsActions.setTotal(r.total));
          dispatch(notificationsActions.addNotification({
            id: 'added-' + r.newid,
            type: 'success',
            header: 'Nyt ord tilføjet',
            text: `"${wordToShow}" blev tilføjet til ordbogen`,
            wordid: r.newid
          }));
          generateWord();
          setDescription('');
        } else {
          dispatch(notificationsActions.addNotification({
            id: (Math.random() + 1).toString(36).substring(7), // generate "random" id
            type: 'error',
            header: 'Fejl',
            text: `Der skete desværre en fejl. Prøv igen`,
          }));
        }
      })
    }
  };

  const sitekey = process.env.REACT_APP_CAPTCHA_SITE_KEY ?? '';

  return (
    <div className="container generate-container">
      <form onSubmit={onSubmit}>
        <div className="jumbotron">
          Mangler det danske sprog dette ord?
          {loading && (
            <>
              <br/>
              <Loading color="#ffffff" />
            </>
          )}
          {!loading && <h1 id="showword">{wordToShow}</h1>}
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="d-grid">
              <input
                className="btn btn-success btn-lg btn-block"
                type="submit"
                value="Absolut! Gem det i ordbogen med nedenstående forklaring"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-grid">
              <button
                type="button"
                onClick={generateWord}
                className="btn btn-primary btn-lg btn-block"
              >
                Nix, giv mig et nyt
              </button>
            </div>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-12">
          <ReCAPTCHA
            sitekey={sitekey}
            ref={captchaRef}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <h4>Skal der noget med mellem de to led?</h4>
          </div>
        </div>

        <div className="row">
          {joiningOptions.map((o, i) => (
            <div className="col-md-3" key={i}>
              <div className="d-grid">
                <button
                  type="button"
                  className={`btn ${
                    joiner !== o.joiner ? "btn-light" : "btn-info selectedjoin"
                  }`}
                  onClick={changeJoiner(o.joiner)}
                >
                  {o.text}
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <br />
              <div className="input text">
                <label htmlFor="WordAuthor">
                  Indtast dit navn hvis du vil krediteres:
                </label>
                <input
                  className="form-control"
                  maxLength={1000}
                  type="text"
                  value={author}
                  onChange={e => setAuthor(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input textarea">
                <p>
                  <label htmlFor="WordExplanation">
                    Giv ordet en forklaring:
                  </label>
                </p>
                <TextEditor initialText={description} onChange={setDescription} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
