import { configureStore, Store } from '@reduxjs/toolkit'
import { notificationsReducer } from './notifications/notificationsSlice';
import { StoreState } from './store-state';
import { wordsReducer } from './words/wordsSlice';

export const store: Store<StoreState> = configureStore({
  reducer: {
    notifications: notificationsReducer,
    words: wordsReducer
  },
});