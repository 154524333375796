import classNames from "classnames";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationsActions } from "../../store/notifications/notificationsSlice";
import { StoreState } from "../../store/store-state";
import "./notifications-style.css";

export const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state: StoreState) => state.notifications.notifications
  );

  const onClose = useCallback(
    (id: string) => {
      dispatch(notificationsActions.closeNotification(id));
    },
    [dispatch]
  );

  return (
    <div className="toast-container top-0 end-0 p-3">
      {notifications.map((n) => (
        <div
          className={classNames('toast', 'show', n.type === 'error' && 'bg-danger-subtle')}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          key={`notification-${n.id}`}
        >
          <div className="toast-header">
            <img
              src="img/g.jpg"
              className="rounded me-2 notification-logo"
              alt="small logo"
            />
            <strong className="me-auto">{n.header}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => onClose(n.id)}
            />
          </div>
          <div className="toast-body">{n.text}</div>
        </div>
      ))}
    </div>
  );
};
