import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

import './text-editor.css';

interface Props {
  initialText: string;
  onChange: (text: string) => void;
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  ],
};

const formats = [
  'bold', 'italic', 'underline',
  'list', 'bullet',
];

export const TextEditor: React.FC<Props> = ({initialText, onChange}) => {

  return (
    <div className="text-editor">
      <ReactQuill value={initialText}
        modules={modules}
        formats={formats}
        onChange={onChange} />
    </div>
  );
};
