import striptags from 'striptags';

const stripAttributes = (elm: Element) => {
  for (let j=0; j < elm.attributes.length; j++) {
    elm.removeAttribute(elm.attributes[j].name);  
  }

  for (let i=0; i < elm.children.length; i++) {
    stripAttributes(elm.children[i]);
  }
}

export class HTMLSanitizer {
  public static defaultAllowedTags = [
    'b',
    'br',
    'em',
    'i',
    'li',
    'ol',
    'p',
    'strong',
    'ul',
  ];

  public static titleAllowedHTML = ['b', 'i', 'em', 'strong',];

  public static stripHTML(html?: string, allowedTags = this.defaultAllowedTags): string {
    if (!html) {
      return '';
    }
    const result = striptags(html, allowedTags);

    // Parse HTML to strip all attributes
    const parser = new DOMParser();
    let parsedResult = parser.parseFromString(result, 'text/html');
    for (let i=0; i < parsedResult.children.length; i++) {
      stripAttributes(parsedResult.children[i]);
    }

    return parsedResult.body.innerHTML;
  }

  public static noHTML(html: string): string {
    if (!html) {
      return '';
    }
    return HTMLSanitizer.stripHTML(html, []);
  }

  public static titleHTML(html: string): string {
    if (!html) {
      return '';
    }
    return HTMLSanitizer.stripHTML(html, HTMLSanitizer.titleAllowedHTML);
  }
}
