import classNames from "classnames";
import "./style.css";

interface Props {
  current: string;
  onChange: (letter: string) => void;
}

export const Alphabet: React.FC<Props> = ({ current, onChange }) => {
  const letters = [
    "alle",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "æ",
    "ø",
    "å",
  ];

  return (
    <div className="btn-group alphabet justify-content-center">
      {letters.map((l) => (
        <button
          key={l}
          className={classNames(
            "btn",
            "btn-sm",
            current !== l && "btn-outline-secondary",
            current === l && "btn-primary"
          )}
          onClick={() => onChange(l)}
        >
          {l}
        </button>
      ))}
    </div>
  );
};
