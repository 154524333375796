import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api, WordsList } from "../../api/words-api";
import { HTMLSanitizer } from "../../utils/html-sanitizer";
import { Alphabet } from "../components/alphabet/alphabet";
import { Paging } from "../components/paging/paging";
import { SortingHeader } from "../components/sorting-header/sorting-header";
import "./style.css";

export const Dictionary = () => {
  const [letter, setLetter] = useState("alle");
  const [page, setPage] = useState(0);
  const [orderby, setOrderby] = useState("created");
  const [orderbydir, setOrderbydir] = useState("desc");
  const [list, setList] = useState<WordsList>();

  useEffect(() => {
    api.words(page, letter, orderby, orderbydir).then((l) => {
      if (l) {
        setList(l);
      }
    });
  }, [letter, page, orderby, orderbydir, setList]);

  return (
    <div className="container content">
      <div className="row">
        <div className="col-md-12">
          <h2>Ordbogen</h2>
          <Alphabet current={letter} onChange={setLetter} />

          <Paging page={page} pages={20} onChange={setPage} />

          <table className="table table-hover">
            <thead>
              <tr>
                <th>
                  <SortingHeader
                    identifier="word"
                    title="Alfabetisk"
                    orderby={orderby}
                    orderbydir={orderbydir}
                    setOrderby={setOrderby}
                    setOrderbydir={setOrderbydir}
                  />
                </th>
                <th>
                  <SortingHeader
                    identifier="explanation"
                    title="Ordforklaring"
                    orderby={orderby}
                    orderbydir={orderbydir}
                    setOrderby={setOrderby}
                    setOrderbydir={setOrderbydir}
                  />
                </th>
                <th>
                  <SortingHeader
                    identifier="created"
                    title="Efter dato"
                    orderby={orderby}
                    orderbydir={orderbydir}
                    setOrderby={setOrderby}
                    setOrderbydir={setOrderbydir}
                  />
                </th>
                <th>
                  <SortingHeader
                    identifier="likes"
                    title="Likes"
                    orderby={orderby}
                    orderbydir={orderbydir}
                    setOrderby={setOrderby}
                    setOrderbydir={setOrderbydir}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.words.map((c, i) => (
                <tr key={page + i}>
                  <td>
                    <Link to={`/word/${c.id}`}>{c.word}</Link>
                  </td>
                  <td>
                    <div className="index-explanation">
                      {HTMLSanitizer.noHTML(c.explanation)}
                    </div>
                  </td>
                  <td>{c.created}</td>
                  <td>{c.likes}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <Paging page={page} pages={20} onChange={setPage} />
        </div>
      </div>
    </div>
  );
};
