interface Props {
  identifier: string;
  title: string;
  orderby: string;
  orderbydir: string;
  setOrderby: (s: string) => void;
  setOrderbydir: (s: string) => void;
}

export const SortingHeader: React.FC<Props> = ({
  identifier,
  title,
  orderby,
  orderbydir,
  setOrderby,
  setOrderbydir,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <button
        onClick={() => {
          setOrderby(identifier);
          setOrderbydir(orderbydir === "asc" ? "desc" : "asc");
        }}
        className="btn btn-link"
      >
        {title}
      </button>
      <div>
        {orderby === identifier && orderbydir === "desc" && (
          <i className="bi bi-chevron-down"></i>
        )}
        {orderby === identifier && orderbydir === "asc" && (
          <i className="bi bi-chevron-up"></i>
        )}
        {orderby !== identifier && <i className="bi bi-chevron-expand"></i>}
      </div>
    </div>
  );
};
