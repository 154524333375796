import classNames from "classnames";

interface Props {
  /** The current page */
  page: number;
  /** Total number of pages */
  pages: number;
  onChange: (p: number) => void;
}

const PADDING_PAGES = 8;

export const Paging: React.FC<Props> = ({ page, pages, onChange }) => {
  const hasPrev = page > 0;
  const hasNext = page < pages - 1;

  const prevPadding = [];
  for (let p = page - 1; p >= 0 && p >= page - PADDING_PAGES; p--) {
    prevPadding.push(p);
  }
  prevPadding.sort((a,b) =>  a - b);

  const nextPadding = [];
  for (let p = page + 1; p < pages && p <= page + PADDING_PAGES; p++) {
    nextPadding.push(p);
  }
  nextPadding.sort((a,b) =>  a - b);

  return (
    <div>
      <ul className="pagination justify-content-center">
        <li className={classNames("page-item", !hasPrev && "disabled")}>
          <button className="page-link" onClick={() => onChange(page - 1)}><i className="bi bi-chevron-left"></i></button>
        </li>
        {prevPadding.map((p) => (
          <li className="page-item" key={`prev${p}`}>
            <button className="page-link" onClick={() => onChange(p)}>{p + 1}</button>
          </li>
        ))}
        <li className="page-item active">
          <button className="page-link">{page + 1}</button>
        </li>
        {nextPadding.map((p) => (
          <li className="page-item" key={`next${p}`}>
            <button className="page-link" onClick={() => onChange(p)}>{p + 1}</button>
          </li>
        ))}
        <li className={classNames("page-item", !hasNext && "disabled")}>
          <button className="page-link" onClick={() => onChange(page + 1)}><i className="bi bi-chevron-right"></i></button>
        </li>{" "}
      </ul>
    </div>
  );
};
