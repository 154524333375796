import { NavLink, Outlet } from "react-router-dom";

import { TotalWords } from "../components/total-words/total-words";
import { Notifications } from "../notifications/notifications";

export const Layout = () => {
  return (
    <>
      <Notifications />
      <div className="container logo-container">
        <img src="img/godtordigenlogo.jpg" alt="Godt Ord Igen" />
        <TotalWords />
      </div>
      <div className="menu">
        <div className="container">
          <nav
            className="navbar navbar-expand-sm"
            aria-label="Third navbar example"
          >
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarsExample03"
                aria-controls="navbarsExample03"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarsExample03">
                <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/">
                      Forside
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/words">
                      Ordbogen
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/contributors">
                      Bidragydere
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/about">
                      Om Godt Ord Igen
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* An <Outlet> renders whatever child route is currently active,
    so you can think about this <Outlet> as a placeholder for
    the child routes we defined above. */}
      <Outlet />

      <div className="container footer text-center">
        <div className="row">
          <div className="col-md-4">
            <a href="https://pensum.dk/godt-ord-igen_jakob-fredslund/9788799993321">
              <img
                src="img/godtordigen1.png"
                alt="Køb bogen om 'Godt Ord Igen'"
              />
            </a>
          </div>

          <div className="col-md-4">
            <a href="https://pensum.dk/godt-ord-igen-2_jakob-fredslund/9788799993338">
              <img
                src="img/godtordigen2.jpg"
                alt="Køb bogen om 'Godt Ord Igen'"
              />
            </a>
          </div>
          <div className="col-md-4">
            <a href="https://pensum.dk/godt-ord-igen-lokumsstruds_jakob-fredslund/9788799993345">
              <img
                src="img/godtordigen3.png"
                alt="Køb bogen om 'Godt Ord Igen'"
              />
            </a>
          </div>
        </div>

        <div className="pull-right">
          <span>Kommentarer til denne side kan også gives på </span>
          <a href="http://www.facebook.com/groups/kristersoplevelser/">
            vores Facebookgruppe
          </a>
          <span>. Se anden ordballade </span>
          <a href="http://www.daimi.au.dk/~chili/alleordlege.html">her</a>.
        </div>
      </div>
    </>
  );
};
