export const About = () => {
  return (<div className="container content">
	<p>
Måske er det bare mig der synes det her er et skideskægt koncept..:
	</p>
	<p>
Man tager et tilfældigt navneord fra Retskrivningsordbogen og sætter det sammen med
et andet tilfældigt navneord, og vupti har man et nyt ord. Ret ofte et ord, der er billedskabende
og derfor bare lige skal have sin indlysende forklaring skrevet ned af rette vedkommende.
	</p>
	<p>
Og her kommer du ind i billedet! Jeg håber du vil lege med. Og jeg håber også engang at
kunne samle de bedste ord i en eller anden form for udgivelse. Så hvis du opretter et ord her i ordbogen,
giver du din tilladelse til at jeg evt. bruger det. Du skal nok blive krediteret med navn.
	</p>
	<p>
Godt Ord Igen bygger på en gammel ide af Thomas Holmen Jørgensen - stor tak!
Også tak til Dansk Sprognævn, hvor jeg har hentet ordbogen.
Endelig en stor tak til Morten Bohøj for at kode den nuværende version af ordbogen.
	</p>
	<p>
Bedste hilsner,<br />
Jakob Fredslund
	</p>
	<p>
PS. Der er 1860369424 kombinationer. Og så er der 315467448 kombinationer af
verbum+navneord, 35928956 kombinationer af adverbium+navneord samt 415878744 kombinationer
af navneord+adjektiv, som også kan dukke op i generatoren. Desuden vægtes ord under 16 bogstaver højere.
	</p>
	<p>
PPS. <a href="mailto:jakob@godt-ord-igen.dk?Subject=GodtOrdIgen">Kommentarer</a> er velkomne!
	</p>
	<p>
PPPS. Kig <a href="http://palindromer.dk/">her</a> hvis du vil se noget af det andet ord-hejs jeg har lavet.
	</p>
</div>)
}